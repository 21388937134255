import { Checkbox, CheckboxGroup, Form, Field, CellGroup, Button, Picker, Toast, Cascader, Popup, Tabbar, TabbarItem, Card} from 'vant'
import { areaList } from '@vant/area-data';
import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import 'vant/es/toast/style';


const app = createApp(App);
app.use(router);
app.use(Checkbox, CheckboxGroup, Form, Field, CellGroup, Button, Picker, Toast, Cascader,areaList , Popup,Tabbar, TabbarItem, Card)
app.mount('#app');

var _hmt = _hmt || [];
window._hmt = _hmt; // 将_hmt挂载到window下

(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?89b2dfe9e0b2849858018b460ee383a2";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

