import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'user',
    },
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user'),
    meta: {
      title: '个人中心',
    },
  },
  {
    name: 'vip',
    path: '/vip',
    component: () => import('./view/vip'),
    meta: {
      title: '开通会员',
      requireAuth: true,
    },
  },
  {
    name: 'income',
    path: '/income',
    component: () => import('./view/income/state'),
    meta: {
      title: '积分统计',
      requireAuth: true,
    },
  },  
  {
    name: 'close_dr',
    path: '/close_dr',
    component: () => import('./view/subscribe/close_dr'),
    meta: {
      title: '关闭免打扰模式',
      requireAuth: false,
    },
  },   
  {
    name: 'chat_group',
    path: '/chat_group',
    component: () => import('./view/user/chat_group'),
    meta: {
      title: '进群',
      requireAuth: false,
    },
  },    
  {
    name: 'groups',
    path: '/groups',
    component: () => import('./view/user/groups'),
    meta: {
      title: '进群',
      requireAuth: true,
    },
  },
  // {
  //   name: 'cart',
  //   path: '/cart',
  //   component: () => import('./view/cart'),
  //   meta: {
  //     title: '购物车',
  //   },
  // },
  // {
  //   name: 'goods',
  //   path: '/goods',
  //   component: () => import('./view/goods'),
  //   meta: {
  //     title: '商品详情',
  //   },
  // },
  {
    name: 'subscribe_setting',
    path: '/subscribe_setting',
    component: () => import('./view/subscribe/setting'),
    meta: {
      title: '订阅设置',
      requireAuth: true,
    },
  },
  {
    name: 'subscribe',
    path: '/subscribe',
    component: () => import('./view/subscribe/subscribe'),
    meta: {
      title: '关注公众号'
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login'),
    meta: {
      title: '微信登陆',
    },
  },
  {
    name: 'wxcallback',
    path: '/wxcallback',
    component: () => import('./view/login/callback'),
    meta: {
      title: '微信登陆',
    },
  },  
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  if (to.meta.requireAuth) {
    if (localStorage.getItem("token")) {
      next();
    } else {
      next({
        path: "/login",
        query: {redirect: to.fullPath}
      })
    }
  } else {
    next();
  }
  
});

export { router };
